import * as React from 'react';
import Header from '../molecules/Header';
import Menu from '../molecules/Menu';

interface Props {
  current: string;
  handleToggle: () => void;
  toggle: boolean;
}

const MenuContainer: React.SFC<Props> = ({ current, handleToggle, toggle }) => (
  <div>
    <Header toggle={toggle} handleToggle={handleToggle} />
    <Menu toggle={toggle} current={current} />
  </div>
);

export default MenuContainer;
