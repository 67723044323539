import * as React from 'react';
import styled from '../../theme';
import SocialIcon from '../atoms/SocialIcon';

const List = styled.ul`
  position: fixed;
  display: none;
  top: 50%;
  right: 20px;
  z-index: 500;
  transform: translate(50%, -50%);
  transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  padding: 0px;
  margin: 0px;
  list-style: none;
  @media all and (min-width: 50em) {
    display: block;
  }
`;

const DesktopSocial: React.SFC<any> = () => (
  <List>
    <SocialIcon
      anchor='https://github.com/justlutes'
      icon='github'
      aria='Check My Github'
    />
    <SocialIcon
      anchor='https://www.linkedin.com/in/kylelutes'
      icon='linkedin'
      aria='Add Me On LinkedIn'
    />
    <SocialIcon
      anchor='https://www.facebook.com/KyleLutesFreelance/'
      icon='facebook'
      aria='Add Me On Facebook'
    />
    <SocialIcon
      anchor='https://twitter.com/KyleLutes2'
      icon='twitter'
      aria='Follow Me On Twitter'
    />
  </List>
);

export default DesktopSocial;
