import * as styledComponents from 'styled-components';

const {
  default: styled,
  css,
  injectGlobal,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  IThemeInterface
>;

export interface IThemeInterface {
  default: string;
  about: string;
  home: string;
  work: string;
  contact: string;
  [key: string]: string;
}

export const theme: IThemeInterface = {
  default: 'black',
  about: '#ff6348',
  home: '#245b7e',
  work: '#00b894',
  contact: '#6c5ce7',
};

export default styled;
export { css, injectGlobal, keyframes, ThemeProvider };
