import * as React from 'react';
import styled, { theme } from '../../theme';

interface ColorProps {
  toggle: boolean;
  current: string;
  className?: string;
}

const Color: React.SFC<ColorProps> = ({ className }) => (
  <div className={className} />
);

const Wrapper = styled(Color)`
  background: ${(props: ColorProps) => theme[props.current] || theme.default};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 500ms cubic-bezier(1, 0, 0, 1) 0ms;
  z-index: 11;
  opacity: ${props => (props.toggle ? 1 : 0)};
  pointer-events: ${props => (props.toggle ? 'all' : 'none')};
`;

interface Props {
  toggle: boolean;
  current: string;
}

const MenuColor: React.SFC<Props> = ({ current, toggle }) => (
  <Wrapper toggle={toggle} current={current} />
);

export default MenuColor;
