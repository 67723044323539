import * as React from 'react';
import { Link } from 'gatsby';
import styled, { theme } from '../../theme';

import NavItem from '../atoms/NavItem';
import Logo from '../atoms/Logo';
import SocialIcon from '../atoms/SocialIcon';

interface InnerProps {
  toggle: boolean;
  className?: string;
  children?: React.ReactChild[] | React.ReactChild;
}

const MenuWrapper: React.SFC<InnerProps> = ({
  toggle,
  className,
  children,
}) => <div className={className}>{children}</div>;

const Wrapper = styled(MenuWrapper)`
  position: fixed;
  top: 0;
  left: 0px;
  right: 80px;
  height: 100%;
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 500ms cubic-bezier(1, 0, 0, 1) 0ms,
    left 500ms cubic-bezier(1, 0, 0, 1) 0ms;
  transform: ${props =>
    props.toggle ? 'translateX(0%)' : 'translateX(-100%)'};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: white;
  padding: 0px;
  @media all and (min-width: 50em) {
    left: 40px;
    right: 20%;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  }
`;

const Container = styled(MenuWrapper)`
  transform: ${props =>
    props.toggle ? 'translateX(0%)' : 'translateX(-100%)'};
  min-height: 100%;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  position: relative;
  z-index: 10;
  @media all and (min-width: 50em) {
    padding: 0;
    padding-left: calc(16vw - 60px);
  }
`;

const NavWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
  margin-left: -10px;
  margin-top: 100px;
  @media all and (min-width: 50em) {
    margin-bottom: 60px;
    margin-top: 90px;
  }
`;

const LogoWrap: React.SFC<InnerProps> = ({ toggle, className, children }) => (
  <Link to="/" className={className}>
    {children}
  </Link>
);

const LogoWrapper = styled(LogoWrap)`
opacity: ${props => (props.toggle ? 1 : 0)};
transform 600ms cubic-bezier(1, 0, 0, 1) 200ms, opacity 400ms linear 350ms;
margin: 40px 0;
&:hover {
  opacity: 0.5;
}
@media all and (min-width: 50em) {
  display: none;
}
`;

const SocialWrap: React.SFC<InnerProps> = ({ toggle, className, children }) => (
  <ul className={className}>{children}</ul>
);

const SocialIcons = styled(SocialWrap)`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: ${props => (props.toggle ? 1 : 0)};
  margin: 0;
  list-style: none;
  margin-bottom: 40px;
  padding: 0;
  transform: translateY(0px);
  transition: transform 600ms cubic-bezier(1, 0, 0, 1) 200ms,
    opacity 400ms linear 350ms;
  @media all and (min-width: 50em) {
    display: none;
  }
`;

interface Props {
  toggle: boolean;
  current: string;
}

const Menu: React.SFC<Props> = ({ current, toggle }) => (
  <Wrapper toggle={toggle}>
    <Container toggle={toggle}>
      <LogoWrapper toggle={toggle}>
        <Logo fill={theme[current]} height="40px" width="40px" />
      </LogoWrapper>
      <NavWrapper>
        <NavItem anchor="home" text="Home" current={current} />
        <NavItem anchor="about" text="About" current={current} />
        <NavItem anchor="work" text="Work" current={current} />
        <NavItem anchor="contact" text="Contact" current={current} />
      </NavWrapper>
      <SocialIcons toggle={toggle}>
        <SocialIcon
          anchor="https://github.com/klutes90"
          icon="github"
          aria="Check My Github"
        />
        <SocialIcon
          anchor="https://www.linkedin.com/in/kylelutes"
          icon="linkedin"
          aria="Add Me On LinkedIn"
        />
        <SocialIcon
          anchor="https://www.facebook.com/KyleLutesFreelance/"
          icon="facebook"
          aria="Add Me On Facebook"
        />
        <SocialIcon
          anchor="https://twitter.com/KyleLutes2"
          icon="twitter"
          aria="Follow Me On Twitter"
        />
      </SocialIcons>
    </Container>
  </Wrapper>
);

export default Menu;
