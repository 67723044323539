import * as React from 'react';
import styled from '../../theme';

interface InnerProps {
  toggle: boolean;
  className?: string;
}

const Border: React.SFC<InnerProps> = ({ className, toggle }) => (
  <div className={className} />
);

const B = styled(Border)`
  position: fixed;
  z-index: 100;
  background: white;
  height: 20px;
  width: 20px;
  pointer-events: none;
  transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
`;

const BTop = styled(B)`
  top: 0;
  left: 0;
  width: 100%;
  transform-origin: top center;
  transform: ${props =>
    props.toggle
      ? 'translate(0, 0%) scale(1)'
      : 'translate(0, -100%) scale(1)'};
  @media all and (min-width: 50em) {
    height: 40px;
    top: ${props => (props.toggle ? '0' : '40px')};
    transform: ${props =>
      props.toggle
        ? 'translate(0, 0%) scale(2)'
        : 'translate(0, -100%) scale(1)'};
  }
`;

const BBot = styled(B)`
  bottom: 0;
  left: 0;
  width: 100%;
  transform-origin: bottom center;
  transform: ${props =>
    props.toggle ? 'translate(0, 0%) scale(1)' : 'translate(0, 100%) scale(1)'};
  @media all and (min-width: 50em) {
    height: 40px;
    bottom: ${props => (props.toggle ? '0' : '40px')};
    transform: ${props =>
      props.toggle
        ? 'translate(0, 0%) scale(2)'
        : 'translate(0, 100%) scale(1)'};
  }
`;

const BLeft = styled(B)`
  top: 0;
  left: 0;
  height: 100%;
  transform-origin: left center;
  transform: ${props =>
    props.toggle
      ? 'translate(0, 0%) scale(1)'
      : 'translate(-100%, 0) scale(1)'};
  @media all and (min-width: 50em) {
    width: 40px;
    left: ${props => (props.toggle ? '0' : '40px')};
    transform: ${props =>
      props.toggle
        ? 'translate(0, 0%) scale(2)'
        : 'translate(-100%, 0) scale(1)'};
  }
`;

const BRight = styled(B)`
  top: 0;
  right: 0;
  height: 100%;
  transform-origin: right center;
  transform: ${props =>
    props.toggle ? 'translate(0, 0%) scale(1)' : 'translate(100%, 0) scale(1)'};
  @media all and (min-width: 50em) {
    width: 40px;
    right: ${props => (props.toggle ? '0' : '40px')};
    transform: ${props =>
      props.toggle
        ? 'translate(0, 0%) scale(2)'
        : 'translate(100%, 0) scale(1)'};
  }
`;

interface Props {
  toggle: boolean;
}

const Borders: React.SFC<Props> = ({ toggle }) => (
  <div>
    <BTop toggle={toggle} />
    <BBot toggle={toggle} />
    <BLeft toggle={toggle} />
    <BRight toggle={toggle} />
  </div>
);

export default Borders;
