import * as React from 'react';
import styled, { theme } from '../../theme';

const Anchor = styled.a`
  height: 25px;
  width: 25px;
  line-height; 25;
  display: block;
  text-align: center;
`;

interface SocialProps {
  anchor: string;
  aria: string;
  icon: string;
}

const Icon = styled.i`
  color: #212121;
  font-size: 16x;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: color 333ms ease-in-out;
  @media all and (min-width: 50em) {
    padding: 0;
    font-size: 18px;
  }
  &:hover {
    color: ${theme.home};
  }
`;

const SocialIcon: React.SFC<SocialProps> = ({ anchor, aria, icon }) => (
  <li>
    <Anchor
      href={anchor}
      rel="noopener noreferrer"
      target="_blank"
      aria-label={aria}
    >
      <Icon className={`fa fa-${icon}`} aria-hidden="true" />
    </Anchor>
  </li>
);

export default SocialIcon;
