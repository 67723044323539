import * as React from 'react';
import { Link } from 'gatsby';
import styled, { theme } from '../../theme';

const StyledLink = styled(Link)`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 2.1rem;
  line-height: 1.1;
  font-weight: 900;
  text-decoration: none;
  color: #212121;
  @media all and (min-width: 50em) {
    font-size: 3rem;
  }
`;

const OuterSpan = styled.span`
  padding: 0;
  line-height: 1.1;
  display: inline-block;
  overflow: hidden;
  position: relative;
`;

interface Props {
  text: string;
  current: string;
  anchor: string;
}

interface HighLightProps {
  text: string;
  current: string;
  active: boolean;
  className?: string;
}

const Span: React.SFC<HighLightProps> = ({ text, className }) => (
  <span className={className}>{text}</span>
);

const HighLight = styled(Span)`
  transition-delay: 350ms;
  position: relative;
  transform: translateY(0%);
  padding: 0 10px;
  transition: transform 800ms cubic-bezier(1, 0, 0, 1) 0ms;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    height: 15px;
    background: #6d6d6d;
    opacity: 0.5;
    z-index: -1;
    transform-origin: left center;
    transform: ${props =>
      props.active
        ? 'scaleX(1) translateY(-50%)'
        : 'scaleX(0) translateY(-50%)'};
    background: ${(props: HighLightProps) =>
      theme[props.text.toLowerCase()] || theme.default};
    transition: transform 400ms cubic-bezier(1, 0, 0, 1) 0ms;
    ${StyledLink}:hover & {
      transform: scaleX(1) translateY(-50%);
    }
  }
`;

const NavItem: React.SFC<Props> = ({ current, text, anchor }) => (
  <li>
    <StyledLink to={`/${anchor === 'home' ? '' : anchor}`}>
      <OuterSpan>
        <HighLight active={current === anchor} current={current} text={text} />
      </OuterSpan>
    </StyledLink>
  </li>
);

export default NavItem;
