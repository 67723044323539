import * as React from 'react';
import styled from '../../theme';
import IntroColor from '../atoms/IntroColor';

const Layout = styled.div`
  position: relative;
  z-index: 1;
  transform-origin: center 50vh;
  transition: transform 500ms cubic-bezier(1, 0, 0, 1) 0ms;
  pointer-events: none;
  min-height: 100vh;
`;

const Container = styled.div`
  position: relative;
  background: #f8f8f8;
  overflow: hidden;
  transition: transform 500ms cubic-bezier(1, 0, 0, 1) 0ms;
  pointer-events: all;
  min-height: 100vh;
`;

const Main = styled.main`
  position: relative;
  min-height: 100vh;
  z-index: 10;
  overflow: hidden;
  transition: transform 1000ms cubic-bezier(1, 0, 0, 1) 0ms,
    padding 500ms cubic-bezier(1, 0, 0, 1) 0ms;
`;

interface Props {
  current: string;
  children?: React.ReactChild | React.ReactChild[];
}

const Wrapper: React.SFC<Props> = ({ children, current }) => {
  if (current === 'work') {
    return (
      <Layout>
        <Container>
          <Main>{children}</Main>
        </Container>
      </Layout>
    );
  }
  return (
    <Layout>
      <Container>
        <Main>
          <IntroColor current={current} />
          {children}
        </Main>
      </Container>
    </Layout>
  );
};

export default Wrapper;
