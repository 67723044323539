import * as React from 'react';
import Helmet from 'react-helmet';

import 'normalize.css';
import '../static/css/style.css';
import '../static/fonts/fontawesome/style.css';

interface TemplateProps {
  children?: any;
}

const TemplateWrapper: React.SFC<TemplateProps> = ({ children }) => (
  <div>
    <Helmet
      title="Kyle Lutes Full Stack Developer"
      meta={[
        { name: 'referrer', content: 'origin' },
        {
          name: 'description',
          content:
            'Online portfolio for fullstack web developer, Kyle Lutes based in Orlando Florida.',
        },
        {
          name: 'viewport',
          content:
            'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
        },
        {
          name: 'theme-color',
          content: '#888',
        },
      ]}
    />
    {children}
  </div>
);

export default TemplateWrapper;
