import * as React from 'react';
import styled, { theme } from '../../theme';

interface Props {
  current: string;
  className?: string;
}
const Color: React.SFC<Props> = ({ className, current }) => (
  <div className={className} />
);
const IntroColor = styled(Color)`
  background: ${(props: Props) => theme[props.current] || theme.default};
  position: absolute;
  left: 0;
  right: 0;
  height: 300px;
  top: 50vh;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
  @media all and (min-width: 50em) {
    height: 100vh;
    left: ${(props: Props) => (props.current === 'home' ? '50%' : 0)};
  }
`;

export default IntroColor;
