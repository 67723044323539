import * as React from 'react';
import styled from '../../theme';

interface InnerProps {
  toggle: boolean;
  onClick?: () => void;
  className?: string;
  children?: React.ReactChild[];
}

const Button: React.SFC<InnerProps> = ({
  onClick,
  toggle,
  className,
  children,
}) => (
  <button onClick={onClick} className={className}>
    {children}
  </button>
);

const Container = styled(Button)`
  position: fixed;
  top: 28px;
  right: 28px;
  border: none;
  appearance: none;
  height: 45px;
  width: 45px;
  text-align: center;
  cursor: pointer;
  z-index: 1000;
  transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  background: white;
  border-radius: 25px;
  @media all and (min-width: 50em) {
  }
  &:focus {
    outline: none;
  }
  @media all and (min-width: 50em) {
    top: 50%;
    left: 40px;
    right: inherit;
    margin-top: -20px;
    height: 40px;
    width: 40px;
    background: transparent;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    transform: ${(props: InnerProps) =>
      props.toggle ? 'translateX(60px)' : ''};
  }
`;

const InnerContainer: React.SFC<InnerProps> = ({
  children,
  className,
  toggle,
}) => <i className={className}>{children}</i>;

interface LineProps {
  toggle: boolean;
  className?: string;
}
const MenuLine: React.SFC<LineProps> = ({ className, toggle }) => (
  <span className={className} />
);

const Inner = styled(InnerContainer)`
  display: flex;
  position: absolute;
  top: 50%;
  right: 50%;
  flex-direction: column;
  align-items: flex-end;
  transform: ${props =>
    props.toggle ? 'translate(50%, -1px)' : 'translate(50%, -50%)'};
  transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  @media all and (min-width: 50em) {
    transform: translate(-20px, -50%);
    right: 0;
  }
`;

const Line = styled(MenuLine)`
  transform: ${props => (props.toggle ? 'rotate(45deg)' : '')};
  width: ${props => (props.toggle ? '20px' : '16px')};
  height: 2px;
  background: #1c1b20;
  display: block;
  transition: all 400ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  @media all and (min-width: 50em) {
    ${Container}:hover & {
      &:nth-child(1) {
        width: ${props => (props.toggle ? '20px' : '20px')};
      }
      &:nth-child(2) {
        width: ${props => (props.toggle ? '20px' : '32px')};
      }
      &:nth-child(3) {
        width: ${props => (props.toggle ? '0' : '18px')};
      }
    }
  }
  &:nth-child(2) {
    margin-top: ${props => (props.toggle ? '-2px' : '2px')};
    width: 20px;
    transform: ${props => (props.toggle ? 'rotate(-45deg)' : '')};
    @media all and (min-width: 50em) {
      margin-top: ${props => (props.toggle ? '-2px' : '3px')};
    }
  }
  &:nth-child(3) {
    margin-top: 2px;
    width: ${props => (props.toggle ? '0' : '12px')};
    @media all and (min-width: 50em) {
      margin-top: 3px;
    }
  }
`;

const T: React.SFC<InnerProps> = ({ className, children, toggle }) => (
  <span className={className}>{children}</span>
);
const Text = styled(T)`
  position: absolute;
  width: 100%;
  top: 15px;
  right: -25px;
  text-align: center;
  display: none;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  letter-spacing: 0.2em;
  transform: rotate(-90deg);
  transition: transform 400ms cubic-bezier(0.7, 0, 0.3, 1) 0ms,
    opacity 150ms linear 0ms, color 150ms linear 0ms;
  @media all and (min-width: 50em) {
    display: block;
    opacity: ${(props: InnerProps) => (props.toggle ? 0 : 1)};
    ${Container}:hover & {
      transform: rotate(-90deg) translateY(-8px);
    }
  }
`;

const TextLetter = styled.span`
  display: inline-block;
  transition: transform 400ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  ${Container}:hover & {
    transform: rotate(-90deg) translateY(-8px);
    &:nth-child(1) {
      transform: translateY(-5px);
    }
    &:nth-child(2) {
      transform: translateY(5px);
    }
    &:nth-child(3) {
      transform: translateY(-2px);
    }
    &:nth-child(4) {
      transform: translateY(3px);
    }
  }
`;

interface Props {
  toggle: boolean;
  handleToggle: () => void;
}

const Hamburger: React.SFC<Props> = ({ handleToggle, toggle }) => (
  <Container onClick={handleToggle} toggle={toggle}>
    <Inner toggle={toggle}>
      <Line toggle={toggle} />
      <Line toggle={toggle} />
      <Line toggle={toggle} />
    </Inner>
    <Text toggle={toggle}>
      <TextLetter>M</TextLetter>
      <TextLetter>E</TextLetter>
      <TextLetter>N</TextLetter>
      <TextLetter>U</TextLetter>
    </Text>
  </Container>
);

export default Hamburger;
