import * as React from 'react';
import { Link } from 'gatsby';
import styled from '../../theme';
import DesktopSocial from './DesktopSocial';
import Toggle from '../atoms/Hamburger';
import Logo from '../atoms/Logo';

const StyledLink = styled(Link)`
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 40px;
  z-index: 11;
  padding: 0;
  line-height: 40px;
  height: 40px;
  width: 40px;
  margin: 0;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  @media all and (min-width: 50em) {
    position: fixed;
    top: 0;
    z-index: 1000;
  }
`;

interface Props {
  toggle: boolean;
  handleToggle: any;
}

const Header: React.SFC<Props> = ({ toggle, handleToggle }) => (
  <header>
    <StyledLink to="/">
      <Logo fill="" height="40px" width="40px" />
    </StyledLink>
    <Toggle toggle={toggle} handleToggle={handleToggle} />
    <DesktopSocial />
  </header>
);

export default Header;
