import * as React from 'react';
import MenuContainer from '../organisms/MenuContainer';
import Wrapper from '../organisms/Wrapper';
import Borders from '../atoms/Borders';
import MenuColor from '../atoms/MenuColor';

interface State {
  toggle: boolean;
}

interface Props {
  current: string;
  children?: React.ReactChild | React.ReactChild[];
}

export default class Container extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      toggle: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
  }
  handleToggle = () => this.setState({ toggle: !this.state.toggle });
  render() {
    return (
      <div>
        <MenuContainer
          toggle={this.state.toggle}
          current={this.props.current}
          handleToggle={this.handleToggle}
        />
        <Wrapper current={this.props.current}>{this.props.children}</Wrapper>
        <Borders toggle={this.state.toggle} />
        <MenuColor toggle={this.state.toggle} current={this.props.current} />
      </div>
    );
  }
}
